<template>
  <v-container>
    <base-material-card icon="mdi-application-export">
      <v-data-table
        :headers="headers"
        :items="exports"
        :items-per-page="10"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Data Exports</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-dialog
              v-model="dialog"
              max-width="800px"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Create Export
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Create Data Export</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="newExport.title"
                          label="Export Title*"
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="newExport.type"
                          :items="exportTypes"
                          label="Select Type"
                          solo
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="newExport.portals"
                          :items="portals"
                          label="Select portals"
                          multiple
                          solo
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="newExport.tags"
                          :items="tags"
                          solo
                          dense
                          chips
                          small-chips
                          label="Select Tags"
                          multiple
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="cancelCreate"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="createExport"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogCopy" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">Copy Data Export</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="copyExportName"
                          :rules="[() => !!copyExportName || 'Name is required!', copyNameCheck]"
                          :error-messages="errorMessages"
                          label="Export Title*"
                          solo
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="cancelCopy"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    :disabled="copyExportName === copyExportItem.title"
                    @click="copyExport"
                  >
                    Copy
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this export?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteExport">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top class="ml-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                text
                icon
                color="green lighten-2"
                v-bind="attrs"
                v-on="on"
                @click.stop="downloadExport(item)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>Download</span>
          </v-tooltip>
          <v-tooltip top class="ml-2 mr-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                text
                icon
                color="blue lighten-2"
                v-bind="attrs"
                v-on="on"
                @click.stop="copyConfirm(item)"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Copy</span>
          </v-tooltip>
          <v-tooltip top class="ml-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ma-2"
                text
                icon
                color="red lighten-2"
                v-bind="attrs"
                v-on="on"
                @click.stop="deleteConfirm(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-snackbar v-model="snack" top right :timeout="snackTimeout" :color="snackColor">
        <v-progress-circular
          v-if="snackColor === 'info'"
          color="white"
          indeterminate
        ></v-progress-circular>
        {{ snackText }}
      </v-snackbar>
    </base-material-card>
  </v-container>
</template>

<script>
import Exports from '@/Services/Exports/Exports'
import Portals from '@/Services/Portals/GetPortals'
import GetTags from '@/Services/TagManagement'
export default {
  name: 'Exports',
  data () {
    return {
      loadingExports: false,
      exports: [],
      exportTypes: [],
      errorMessages: '',
      portals: [],
      tags: [],
      headers: [
        {
          text: 'ID',
          value: 'id',
          align: 'start'
        },
        {
          text: 'Title',
          value: 'title'
        },
        {
          text: 'Date Created',
          value: 'dateCreated'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: 'false'
        }
      ],
      dialog: false,
      dialogDelete: false,
      deleteExportID: null,
      dialogCopy: false,
      copyExportItem: {},
      copyExportName: '',
      copyNameRules: {
        required: value => !!value || 'Required'
      },
      newExport: {
        title: '',
        tags: [],
        type: null,
        portals: []
      },
      exportDownloadLink: null,
      snack: false,
      snackColor: '',
      snackText: null,
      snackTimeout: null
    }
  },
  created () {
    this.getExports()
    this.initFormData()
  },
  methods: {
    async initFormData () {
      await Exports.getTypes().then((res) => {
        if (res.status === 200) {
          res.data.forEach((element) => {
            this.exportTypes.push({ value: element.id, text: element.name })
          })
        } else {
          this.snackError('Failed to retrieve export types')
        }
      })
      await Portals.getPortals(100).then((response) => {
        if (response.status === 200) {
          response.data.results.forEach((element) => {
            this.portals.push({ value: element.id, text: element.name })
          })
        } else {
          this.snackError('Failed to retrieve available portals')
        }
      })
      await GetTags.getTags().then((res) => {
        if (res.status === 200) {
          res.data.forEach((element) => {
            this.tags.push({ value: element.id, text: element.tagName })
          })
        } else {
          this.snackError('Failed to retrieve tags')
        }
      })
    },
    async getExports () {
      this.snackLoading('Fetching available data exports.')
      this.loadingExports = true
      await Exports.getExports().then((res) => {
        if (res.status === 200) {
          this.$set(this, 'exports', res.data)
          this.snackSuccess('Data Exports Loaded.')
        } else {
          this.snackError('Could not load data exports.')
        }
      })
    },
    copyConfirm (item) {
      this.copyExportItem = item
      this.dialogCopy = true
    },
    copyNameCheck () {
      this.errorMessages = this.copyExportName !== this.copyExportItem.title ? '' : 'Name must not match original export!'
      return true
    },
    cancelCopy () {
      this.copyExportItem = {}
      this.dialogCopy = false
    },
    async copyExport () {
      this.snackLoading('Copying Export...')
      await Exports.copyExport(this.copyExportItem.id, this.copyExportName).then((res) => {
        if (res.status === 200) {
          this.snackSuccess('Data Export Copied.')
          this.$nextTick(() => {
            this.getExports()
            this.dialogCopy = false
          })
        } else {
          this.snackError('Could not copy this export. Please try again later.')
        }
      })
    },
    deleteConfirm (item) {
      this.deleteExportID = item.id
      this.dialogDelete = true
    },
    async deleteExport () {
      this.snackLoading('Deleting Export...')
      await Exports.deleteExport(this.deleteExportID).then((res) => {
        if (res.status === 200) {
          this.snackSuccess('Data Export Deleted.')
          this.$nextTick(() => {
            this.getExports()
            this.dialogDelete = false
          })
        } else {
          this.snackError('Could not delete this export. Please try again later.')
        }
      })
    },
    cancelCreate () {
      this.dialog = false
      this.newExport = {
        title: '',
        tags: [],
        portals: [],
        type: null
      }
    },
    async createExport () {
      this.snackLoading('Creating Data Export ...')
      await Exports.createExport(this.newExport).then((res) => {
        if (res.status === 200) {
          this.snackSuccess('Data Export Created.')
          this.$nextTick(() => {
            this.newExport = {
              title: '',
              tags: [],
              portals: [],
              type: null
            }
            this.getExports()
            this.dialog = false
          })
        } else {
          this.snackError('Could not create data export. Please try again later.')
        }
      })
    },
    async downloadExport (item) {
      this.snackLoading('Downloading ... ')
      this.downloading = true
      await Exports.downloadExport(item.id).then((res) => {
        if (res.status === 200) {
          this.snackSuccess('Download Complete')
          this.exportDownloadLink = res.data.url
          window.open(this.exportDownloadLink, '_blank')
        } else {
          this.snackError('Could not complete download. Please try again later.')
        }
      })
    },
    snackLoading (loadingText) {
      this.snack = true
      this.snackText = loadingText
      this.snackColor = 'info'
      this.snackTimeout = -1
    },
    snackSuccess (successText) {
      this.snack = true
      this.snackText = successText
      this.snackColor = 'success'
      this.snackTimeout = 3000
    },
    snackError (errorMessage) {
      this.snack = true
      this.snackText = errorMessage
      this.snackColor = 'error'
      this.snackTimeout = 5000
    }
  }
}
</script>
