<script>
import axios from 'axios'
import { getInstance } from '../../auth/index'
const authService = getInstance()
export default {
  async getExports () {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'dataservices/exports'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async createExport (newExport) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'dataservices/exports'
    const exportBody = {
      Title: newExport.title,
      tags: newExport.tags,
      Type: newExport.type,
      portals: newExport.portals
    }
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: exportBody,
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async downloadExport (ID) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'dataservices/exports/' + ID
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async copyExport (id, title) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'dataservices/exports/' + id + '/copy'
    const exportBody = {
      Title: title
    }
    const ops = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: exportBody,
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async deleteExport (ID) {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'dataservices/exports/' + ID
    const ops = {
      method: 'DELETE',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  },
  async getTypes () {
    const token = await authService.getTokenSilently()
    const url = process.env.VUE_APP_APIURL + 'dataservices/exports/types'
    const ops = {
      method: 'GET',
      headers: { 'content-type': 'application/json' },
      url: url
    }
    axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_APIKEY
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const res = await axios(ops)
      return res
    } catch (error) {
      return error.response
    }
  }
}
</script>
